import './style.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
// import { ARButton } from 'three/examples/jsm/webxr/ARButton.js'
import { gsap } from 'gsap'

/**
 * Loaders
 */
const loadingBarElement = document.querySelector('.loading-bar');

let sceneReady = false;
const loadingManager = new THREE.LoadingManager(
    // Loaded
    () =>
    {
        // Wait a little
        window.setTimeout(() =>
        {
            // Animate overlay
            gsap.to(overlayMaterial.uniforms.uAlpha, { duration: 3, value: 0, delay: 1 });

            // Update loadingBarElement
            loadingBarElement.classList.add('ended');
            loadingBarElement.style.transform = '';
        }, 500)

        window.setTimeout(() =>
        {
            sceneReady = true;
        }, 2000)
    },

    // Progress
    (itemUrl, itemsLoaded, itemsTotal) =>
    {
        // Calculate the progress and update the loadingBarElement
        const progressRatio = itemsLoaded / itemsTotal;
        loadingBarElement.style.transform = `scaleX(${progressRatio})`;
    }
)
const gltfLoader = new GLTFLoader(loadingManager);
const cubeTextureLoader = new THREE.CubeTextureLoader(loadingManager);

/**
 * Base
 */
// Debug
const debugObject = {}

// Canvas
const canvas = document.querySelector('canvas.webgl');

// Scene
const scene = new THREE.Scene();

/**
 * Overlay
 */
const overlayGeometry = new THREE.PlaneBufferGeometry(2, 2, 1, 1)
const overlayMaterial = new THREE.ShaderMaterial({
    // wireframe: true,
    transparent: true,
    uniforms:
    {
        uAlpha: { value: 1 }
    },
    vertexShader: `
        void main()
        {
            gl_Position = vec4(position, 1.0);
        }
    `,
    fragmentShader: `
        uniform float uAlpha;

        void main()
        {
            gl_FragColor = vec4(0.0, 0.0, 0.0, uAlpha);
        }
    `
})
const overlay = new THREE.Mesh(overlayGeometry, overlayMaterial);
scene.add(overlay);

/**
 * Update all materials
 */
const updateAllMaterials = () =>
{
    scene.traverse((child) =>
    {
        if(child instanceof THREE.Mesh && child.material instanceof THREE.MeshStandardMaterial)
        {            
            child.material.roughness = 0.15;
            child.material.envMap = environmentMap
            child.material.envMapIntensity = debugObject.envMapIntensity;
            child.material.needsUpdate = true;
            child.castShadow = true;
            child.receiveShadow = true;
            child.material.side = THREE.DoubleSide;
        }
    })
}

/**
 * Environment map
 */
const environmentMap = cubeTextureLoader.load([
    '/textures/environmentMaps/3/px.jpg',
    '/textures/environmentMaps/3/nx.jpg',
    '/textures/environmentMaps/3/py.jpg',
    '/textures/environmentMaps/3/ny.jpg',
    '/textures/environmentMaps/3/pz.jpg',
    '/textures/environmentMaps/3/nz.jpg'
]);

environmentMap.encoding = THREE.sRGBEncoding;

// scene.background = environmentMap
scene.environment = environmentMap;

debugObject.envMapIntensity = 5;

/**
 * Models
 */
gltfLoader.load(
    '/models/Truck/truck.glb',
    (gltf) =>
    {
        gltf.scene.scale.set(2.5, 2.5, 2.5);
        gltf.scene.rotation.y = Math.PI * 0.5;
        scene.add(gltf.scene);

        updateAllMaterials();
    }
)

/**
 * Points of interest
 */
const raycaster = new THREE.Raycaster()
// const points = [
//     {
//         position: new THREE.Vector3(1.55, 0.3, - 0.6),
//         element: document.querySelector('.point-0')
//     },
//     {
//         position: new THREE.Vector3(0.5, 0.8, - 1.6),
//         element: document.querySelector('.point-1')
//     },
//     {
//         position: new THREE.Vector3(1.6, - 1.3, - 0.7),
//         element: document.querySelector('.point-2')
//     }
// ]

/**
 * Lights
 */
const directionalLight = new THREE.DirectionalLight('#ffffff', 3);
directionalLight.castShadow = true;
directionalLight.shadow.camera.far = 15;
directionalLight.shadow.mapSize.set(1024, 1024);
directionalLight.shadow.normalBias = 0.05;
directionalLight.position.set(0.25, 3, - 2.25);
scene.add(directionalLight);

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth;
    sizes.height = window.innerHeight;

    // Update camera
    camera.aspect = sizes.width / sizes.height;
    camera.updateProjectionMatrix();

    // Update renderer
    renderer.setSize(sizes.width, sizes.height);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100);
camera.position.set(4, 1, - 4);
scene.add(camera);

// Controls
const controls = new OrbitControls(camera, canvas);
controls.enableDamping = true;

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    antialias: true
});
renderer.physicallyCorrectLights = true;
renderer.outputEncoding = THREE.sRGBEncoding;
renderer.toneMapping = THREE.ReinhardToneMapping;
renderer.toneMappingExposure = 3;
renderer.shadowMap.enabled = true;
renderer.shadowMap.type = THREE.PCFSoftShadowMap;
renderer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
renderer.xr.enabled = true;

// document.body.appendChild( ARButton.createButton( renderer ) );

/**
 * Animate
 */
const tick = () =>
{
    // Update controls
    controls.update();

    // Update points only when the scene is ready
    // if(sceneReady)
    // {
    //     // Go through each point
    //     for(const point of points)
    //     {
    //         // Get 2D screen position
    //         const screenPosition = point.position.clone()
    //         screenPosition.project(camera)
    
    //         // Set the raycaster
    //         raycaster.setFromCamera(screenPosition, camera)
    //         const intersects = raycaster.intersectObjects(scene.children, true)
    
    //         // No intersect found
    //         if(intersects.length === 0)
    //         {
    //             // Show
    //             point.element.classList.add('visible')
    //         }

    //         // Intersect found
    //         else
    //         {
    //             // Get the distance of the intersection and the distance of the point
    //             const intersectionDistance = intersects[0].distance
    //             const pointDistance = point.position.distanceTo(camera.position)
    
    //             // Intersection is close than the point
    //             if(intersectionDistance < pointDistance)
    //             {
    //                 // Hide
    //                 point.element.classList.remove('visible')
    //             }
    //             // Intersection is further than the point
    //             else
    //             {
    //                 // Show
    //                 point.element.classList.add('visible')
    //             }
    //         }
    
    //         const translateX = screenPosition.x * sizes.width * 0.5
    //         const translateY = - screenPosition.y * sizes.height * 0.5
    //         point.element.style.transform = `translateX(${translateX}px) translateY(${translateY}px)`
    //     }
    // }

    // Render
    renderer.render(scene, camera);

    // Call tick again on the next frame
    window.requestAnimationFrame(tick);
}

tick();